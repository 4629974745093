// @flow

import { store as CONFIG_STORE } from 'Config';

/**
 * selectCmsModuleByPlaceholder
 *
 * @param {string} placeholder
 * @returns {Function}
 */
export const selectCmsModuleByPlaceholder =
  (placeholder: string): Function =>
  (state: Object): ?Object => {
    const cms = state[CONFIG_STORE.keys.cms];

    return (cms && cms.data && cms.data[placeholder]) || null;
  };
